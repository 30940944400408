<template>
  <a-select
    class="form__select"
    :getPopupContainer="selectContainer"
    :default-value="list[0].value"
  >
    <a-select-option
      v-for="(item, index) of list"
      :key="index"
      :value="item.value"
    >
      {{item.value}}
    </a-select-option>
  </a-select>
</template>

<script>
  import './SelectCommon.styl'

  export default {
    props: ['container', 'list'],
    name: 'SelectCommon',
    methods: {
      selectContainer () {
        return document.querySelector(`.${this.container}`)
      },
    },
  }
</script>
